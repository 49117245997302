import { useGetCorestreamProducts } from 'api/benefit-elections/queries/corestream-products/use-get-corestream-products'
import { events } from 'config/analytics'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useGetBenefitPlanUrl } from 'hooks/benefit-plans/useGetBenefitPlanUrl'
import useMegaMenuDeals from 'hooks/use-mega-menu-deals'
import { useTrackEvent } from 'hooks/use-track-event'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { accountRoutes } from 'routes/routes-map'
import { selectAvailablePlans, selectMenuBenefitCategories } from 'store/benefit-plans/selectors'
import { siteInfoSelector } from 'store/site/selectors'
import { MyProfileUser, myProfileSelector } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { MegaMenuItem } from '../components/mega-menu/types'

/**
 * This hook is responsible for getting all of the benefits and deals needed for the display on the app header menus
 */
export function useMegaMenuItems() {
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const hasBenefits = properties.hasVoluntaryBenefits
	const isBenefitsEligible = hasBenefits && !userProfile.isDiscountsOnly
	const availablePlans = useSelector(selectAvailablePlans)
	const { data: corestreamProducts } = useGetCorestreamProducts(isBenefitsEligible)
	const { allPlanTypes, duplicatePlanTypes } = useSelector(selectMenuBenefitCategories)
	const { dealsMenuItems, supplementalDealsItems } = useMegaMenuDeals()
	const getBenefitPlanUrl = useGetBenefitPlanUrl()
	const { b4b_10023_custom_event_migration } = useFlags()
	const trackEvent = useTrackEvent()
	const track = useTrackDetailedPageEvent()

	const benefitMenuItems = useMemo(() => {
		const multiPlanTypes: MegaMenuItem[] = duplicatePlanTypes.map((dp) => ({
			href: `/benefits/${dp}`,
			label: corestreamProducts?.find((p) => p.productName === dp)?.productLabel ?? dp,
			onClick: () => {
				if (b4b_10023_custom_event_migration) {
					track({
						actionType: 'click',
						elementDetails: dp,
						elementName: 'mega menu category cta',
						elementType: 'button',
					})
				} else {
					trackEvent(events.megaMenu.benefits.category, { category: dp })
				}
			},
		}))

		const singlePlanTypes: MegaMenuItem[] = allPlanTypes
			.filter((p) => !duplicatePlanTypes.includes(p))
			.map((sp) => {
				const benefitPlan = availablePlans.find((bp) => bp.benefitPlanType === sp)

				return {
					href: benefitPlan ? getBenefitPlanUrl(benefitPlan) : '',
					label: benefitPlan?.benefitProductName ?? sp,
					onClick: () => {
						if (b4b_10023_custom_event_migration) {
							track({
								actionType: 'click',
								elementDetails: benefitPlan?.benefitPlanId.toString(),
								elementName: 'mega menu benefit cta',
								elementType: 'button',
							})
						} else {
							trackEvent(events.megaMenu.benefits.category, { category: sp })
						}
					},
				}
			})

		return [...multiPlanTypes, ...singlePlanTypes]
	}, [
		allPlanTypes,
		availablePlans,
		b4b_10023_custom_event_migration,
		corestreamProducts,
		duplicatePlanTypes,
		getBenefitPlanUrl,
		track,
		trackEvent,
	])

	const supplementalBenefitItems = useMemo(() => {
		const options = [
			{
				href: accountRoutes.enrollments,
				label: 'Enrollments',
				onClick: () => {
					if (b4b_10023_custom_event_migration) {
						track({
							actionType: 'click',
							elementName: 'mega menu enrolled benefits cta',
							elementType: 'button',
						})
					} else {
						trackEvent(events.megaMenu.benefits.enrolledBenefits)
					}
				},
			},
			{
				href: accountRoutes.deductions,
				label: 'Deductions',
				onClick: () => {
					if (b4b_10023_custom_event_migration) {
						track({
							actionType: 'click',
							elementName: 'mega menu benefit deductions cta',
							elementType: 'button',
						})
					} else {
						trackEvent(events.megaMenu.benefits.reviewDeductions)
					}
				},
			},
		]

		return options
	}, [b4b_10023_custom_event_migration, track, trackEvent])

	return { benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems }
}

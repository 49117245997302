import { ArrowForwardIos } from '@mui/icons-material'
import { ListItemButton, ListItemText } from '@mui/material'
import { useMegaMenuItems } from 'components/app-header/hooks/use-mega-menu-items'
import { useAppSelector } from 'hooks/redux'
import { User } from 'oidc-client'
import { Dispatch, FC, SetStateAction, useContext, useMemo, useRef, useState } from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { accountRoutes } from 'routes/routes-map'
import { selectIsOE } from 'store/benefit-plans/selectors'
import { MyProfileUser, myProfileSelector } from 'store/user/selectors'
import { LinkBehavior } from 'theme/routing/LinkBehavior'
import { siteInfoSelector } from '../../../../store/site/selectors'
import { RefinementProps } from '../../../../types/search-response-types'
import { SiteInfoResponse } from '../../../../types/tenant-site-types'
import { MegaMenuContext } from '../../app-header'
import MegaMenu from '../mega-menu'
import { DefaultTabs } from '../navigation-tabs/default-tabs'
import styles from './mobile-menu.module.scss'

interface MobileMenuProps extends RefinementProps {
	closeMenu: () => void
	user: User | undefined
	attribute: string
}

const MobileMenu: FC<MobileMenuProps> = ({ closeMenu, user }: MobileMenuProps) => {
	const [showDealsMegaMenu, setShowDealsMegaMenu] = useState<boolean>(false)
	const { benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems } = useMegaMenuItems()
	const mobileMenuContainerRef = useRef<HTMLDivElement>(null)

	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const hasDiscounts = properties.hasDiscounts

	// Only close entire menu when a selection is made
	const handleMegaMenuClose = (toggleFunc: Dispatch<SetStateAction<boolean>>) => (isSelectionMade: boolean) => {
		toggleFunc(false)
		if (isSelectionMade) closeMenu()
	}

	return (
		<MegaMenuContext.Provider
			value={useMemo(
				() => ({ benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems }),
				[benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems],
			)}
		>
			<div className={styles['mobile-menu']} ref={mobileMenuContainerRef}>
				<DefaultTabs user={user} mobileView={true} callback={closeMenu}>
					{user && (
						<Benefits
							anchorRef={mobileMenuContainerRef}
							handleMegaClose={handleMegaMenuClose}
							handleClose={() => {
								closeMenu()
							}}
						/>
					)}
					{user && hasDiscounts && (
						<>
							<ListItemButton onClick={() => setShowDealsMegaMenu(true)}>
								<ListItemText
									primary='Deals'
									primaryTypographyProps={{
										component: 'p',
										variant: 'h2',
									}}
									id='mobile-category-menu'
								/>
								<ArrowForwardIos />
							</ListItemButton>
							<MegaMenu
								handleClose={handleMegaMenuClose(setShowDealsMegaMenu)}
								incomingItems={dealsMenuItems ?? []}
								mobileLabel={'Deals'}
								open={showDealsMegaMenu}
								supplementalItems={supplementalDealsItems}
								anchorRef={mobileMenuContainerRef.current}
								dataTestId='categories-mega-menu'
							/>
						</>
					)}
				</DefaultTabs>
			</div>
		</MegaMenuContext.Provider>
	)
}

export default connectRefinementList(MobileMenu)

const Benefits = (props: {
	anchorRef: React.MutableRefObject<HTMLDivElement | null>
	handleMegaClose: (toggleFunc: Dispatch<SetStateAction<boolean>>) => (isSelectionMade: boolean) => void
	handleClose: () => void
}) => {
	const megaMenuItems = useContext(MegaMenuContext)

	const isOE = useSelector(selectIsOE)

	const [showBenefitsMegaMenu, setShowBenefitsMegaMenu] = useState<boolean>(false)

	const user = useAppSelector((s) => s.user, shallowEqual)
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const hasBenefits = properties.hasVoluntaryBenefits
	const isBenefitsEligible = hasBenefits && !userProfile.isDiscountsOnly

	if (!user) {
		return null
	}

	if (isBenefitsEligible && isOE) {
		return (
			<li>
				<ListItemButton
					component={LinkBehavior}
					href={accountRoutes.enrollments}
					onClick={() => {
						props.handleClose()
					}}
				>
					<ListItemText
						primary='Enrollments'
						id='mobile-my-benefits-menu'
						primaryTypographyProps={{
							component: 'p',
							variant: 'h2',
						}}
					/>
				</ListItemButton>
			</li>
		)
	}

	if (isBenefitsEligible) {
		return (
			<>
				<ListItemButton component='li' onClick={() => setShowBenefitsMegaMenu(true)}>
					<ListItemText
						primary='Benefits'
						primaryTypographyProps={{ component: 'p', variant: 'h2' }}
						id='mobile-my-benefits-menu'
					/>
					<ArrowForwardIos />
				</ListItemButton>
				<MegaMenu
					handleClose={(v) => props.handleMegaClose(setShowBenefitsMegaMenu)(v)}
					incomingItems={megaMenuItems.benefitMenuItems ?? []}
					mobileLabel={'Benefits'}
					open={showBenefitsMegaMenu}
					supplementalItems={megaMenuItems.supplementalBenefitItems}
					anchorRef={props.anchorRef.current}
					dataTestId='benefits-mega-menu'
				/>
			</>
		)
	}
}
